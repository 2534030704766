import moment from 'moment'

export const gettime = (time) => {
  let arr = new Date(time * 1000)
  let y = arr.getFullYear()
  let m = arr.getMonth()
  let d = arr.getDate() < 10 ? '0' + arr.getDate() : arr.getDate()
  let h = arr.getHours() < 10 ? '0' + arr.getHours() : arr.getHours()
  let m1 = arr.getMinutes() < 10 ? '0' + arr.getMinutes() : arr.getMinutes()
  let s = arr.getSeconds() < 10 ? '0' + arr.getSeconds() : arr.getSeconds()
  return `${y}-${
    Number(m) + 1 < 10 ? '0' + (Number(m) + 1) : Number(m) + 1
  }-${d} ${h}:${m1}:${s}`
}

export const indexToWeekday = (index) => {
  switch(index){
    case '1':
        return "Monday"
    case '2':
        return "Tuesday"
    case '3':
        return "Wednesday"
    case '4':
        return "Thursday"
    case '5':
        return "Friday"
    case '6':
        return "Saturday"
    case '7':
        return "Sunday"
  }
}

export const openHourTime = (str) => {
  return new moment(str, 'HH:mm:ss').format('hh:mm a')
}